/* WEB Feel Great landing Page */
import "../../styles/lipstick-home.scss"

import "single-page-nav"

$(document).on("turbo:load", function() {
  // One Page Navigation Setup
  $("#navigation, #footer-nav").singlePageNav({
    offset: $("#navbar").outerHeight(),
    filter: ":not(.external)",
    speed: 1000,
    threshold: 600,
    currentClass: "active",
    beforeStart: function () {
      $(".navbar-collapse-1").collapse("toggle")
    },
    onComplete: function () {
    },
  })
})